// Only run this code in the browser
// This code is used to override the default fetch function in the browser
// to include credentials with every request. This is needed for cross-origin requests
if (typeof window !== 'undefined') {
  const originalFetch = window.fetch;
  window.fetch = (...args) => {
    const [resource, config = {}] = args;

    return originalFetch(resource, {
      ...config,
      credentials: config.credentials || 'include',
    });
  };
}

export const getAPIUrl = (): string => {
  if (!process.env.API_URL) {
    throw new Error('API_URL not set');
  }

  return process.env.API_URL;
};
