import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { fetchMedicationAvailability } from '../nextUtils/glp1/fetchMedicationAvailability';
import { ErrorTracking } from '../common/errorTracking';
import { MainContextType } from '@components/context/MainContextType';
import { track } from '@nextUtils/trackingUtils';

const outOfStockMessage =
  'Currently out of stock, expected to return next month.';

export const defaultAvailability = {
  mounjaro: { isAvailable: true, message: '' },
  wegovy: { isAvailable: false, message: '' },
};

/**
 * Hook to check which of our medication-supported programmes are available.
 *
 * Only used to set this value within MainContext.
 */
export const useAvailableMedication = (
  setState: Dispatch<SetStateAction<MainContextType>>,
): void => {
  const { locale } = useRouter();

  useEffect(() => {
    const setAvailability = async (): Promise<void> => {
      try {
        // Fetch the availability of our medication programmes - this includes both Wegovy and Mounjaro
        const medicationAvailability = await fetchMedicationAvailability();

        // We only allow signups for Mounjaro in the UK
        const allowMedicationSupportedSignups = locale === 'uk';

        // Check if Mounjaro is available - we currently only want to validate Mounjaro availability at the moment
        // removing the existing check for Wegovy as we don't currently offer it. We might still offer Wegovy in the future
        // due to regulatory reasons, so we are not removing the check for it entirely across the codebase.
        const isMounjaroAvailable = !!medicationAvailability?.find(
          ({ medication }) => medication === 'Mounjaro',
        )?.hasAvailability;

        // Only allow signups for Mounjaro if it is available and we are in the UK
        const canSelectMounjaro =
          isMounjaroAvailable && allowMedicationSupportedSignups;

        setState(prevState => ({
          ...prevState,
          medicationAvailability: {
            ...prevState.medicationAvailability,
            mounjaro: {
              isAvailable: canSelectMounjaro,
              message: canSelectMounjaro ? '' : outOfStockMessage,
            },
          },
        }));

        // Only track if signups should be allowed but Mounjaro is unavailable
        if (!isMounjaroAvailable && allowMedicationSupportedSignups) {
          track('Medication unavailable for selection', {
            canSelectMounjaro: `${canSelectMounjaro}`, // This will always be false here, but keep for context
            isMounjaroAvailable: `${isMounjaroAvailable}`, // This will always be false here
            allowMedicationSupportedSignups: `${allowMedicationSupportedSignups}`, // This will always be true here
            locale,
          });
        }
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          medicationAvailability: {
            ...prevState.medicationAvailability,
            mounjaro: { isAvailable: false, message: outOfStockMessage },
          },
        }));

        ErrorTracking.track(error, {
          message: 'Error fetching medication availability',
        });
      }
    };

    setAvailability();
  }, [locale, setState]);
};
